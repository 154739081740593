import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"


export default function Vanor({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;
  const title = "Hur skapar du nya och bättre vanor?"

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Kan du ändra en dålig vana?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ja, du alltid ändra på en dålig vana med det kan ta tid. Kom ihåg att ha tålamod och prova olika saker tills du hittar något som fungerar för dig."
        }
      },
      {
        "@type": "Question",
        "name": "Behöver vi vara medvetna om våra vanor?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Vanor sker automatiskt och vanligtvis behöver man inte vara så medveten om sina vanor. Däremot det kan vara bra att vara medveten om sina vanor om man vill ändra dem."
        }
      },
      {
        "@type": "Question",
        "name": "Vilka är några goda vanor?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Det finns många olika goda vanor, men några av de vanligaste är att motionera, äta hälsosamt, studera regelbundet och få tillräckligt med sömn. Goda vanor kan hjälpa dig att leva ett hälsosammare och lyckligare liv."
        }
      }
    ]
  }


  return (
    <>
      <Page title={title} description="Vanor har många syften i våra liv och de hjälper oss att klara av dagliga aktiviteter utan att behöva tänka så mycket på dem.." schema={schema}>
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="vanor" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>Vanor har många syften i våra liv och de hjälper oss att <strong>klara av dagliga aktiviteter</strong> utan att behöva tänka så mycket på dem. Men ibland kan vi fastna i en rutin med våra vanor och de tjänar oss inte längre lika bra som tidigare. Det är då det är dags att skapa nya och bättre vanor.</p>
            <p>I denna artikeln kommer vi gå igenom <strong>vad en vana är</strong> och hur du kan ändra dem för att skapa nya och bättre vanor. Vi kommer också att diskutera några av de vanligaste dåliga vanorna och hur du kan ersätta dem med hälsosammare alternativ.</p>
            <h2 id="vad-r-en-vana-">Vad är en vana?</h2>
            <p>En vana kan beskrivas som <strong>ett beteende</strong> som du gör automatiskt utan att tänka på det. Vanor inlärs ofta genom upprepning och blir så inrotade i våra liv att vi gör dem utan att ens inse det.</p>
            <p>Det finns både bra och dåliga vanor. Goda vanor kan vara saker som att träna regelbundet, äta hälsosam mat och få tillräckligt med sömn. Dåliga vanor kan vara saker som rökning, att skjuta upp saker och att spendera för mycket pengar.</p>
            <p>Normalt sätt tenderar vi även att associera en <strong>viss stimulus eller miljö till en vana</strong>, vilket gör att miljön vi befinner oss i kan trigga vanan.</p>
            <p>En vana kan vanligtvis delas in tre delar:</p>
            <ol>
              <li><strong>Trigger</strong>: Den signal eller utlösande faktor som startar vanan.</li>
              <li><strong>Rutinen</strong>: Det faktiska beteendet eller handlingen.</li>
              <li><strong>Resultatet</strong>: Belöningen som förstärker beteendet.</li>
            </ol>
            <p>Det kan till exempel vara att se någon annan röka eller att känna sig stressad som utlöser rökvanan. Rutinen är då att ta en cigarett. Resultatet är att rökaren känner sig mer avslappnad.</p>
            <p>Om man utför samma vana om och om igen kommer kopplingen i hjärnan även att stärka och det är därför som det kan vara så svårt att ändra på vanor när de har blivit en del av vårt liv.</p>
            <h3 id="varf-r-har-vi-vanor-">Varför har vi vanor?</h3>
            <p>Vanor är ett sätt för människan att kunna utföras uppgifter som vi vanligtvis utför i våra liv på ett så enkelt och smidigt sätt som möjligt.</p>
            <p>De hjälper oss att <strong>spara tid och energi</strong> genom att vi inte behöver tänka så mycket på vad vi ska göra härnäst eller hur vi ska göra det. Detta beror på att när en vana väl är inlärd blir den automatisk och vi kan göra den utan att tänka efter.</p>
            <p>Vanor kan även hjälpa oss att känna att vi har <strong>mer kontroll</strong> över våra liv. När vi om vi har många vanor kan vi känna att vi har mer kontroll och att våra liv är mer organiserade. Detta kan bidra till att minska stress och ångest.</p>
            <p>Slutligen kan vanor också vara ett sätt att <strong>hantera svåra känslor</strong> eller situationer. Vissa människor kan till exempel tröstäta när de känner sig nedstämda. Även om detta inte är en det kan vara en hälsosam vana och ett sätt att hantera svåra känslor i stunden.</p>
            <h2 id="hur-bygger-man-nya-vanor-">Hur bygger man nya vanor?</h2>
            <p>Det går att bygga nya vanor genom att utföra den. När du <strong>kontinuerligt</strong> utför en vana kommer den  gradvis bli till en del av ditt liv.</p>
            <p>Det svåra kan däremot vara att komma ihåg att utföra vanan i början, vilket vi kan kalla för utlösaren som är det som sätter ingång det önskade beteendet eller handlingen.</p>
            <p>Denna <strong>utlösaren</strong> kan vara allt från att ställa in ett alarm till att lägga dina nycklar på ett visst ställe. Om du exempelvis vill börja gå till gymmet kan du lägg dina träningskläder bredvid sängen så att du ser dem när du vaknar. Därefter går du till gymmet på morgonen.</p>
            <p>Ett annat sätt att bygga upp en ny vana är att <strong>göra det svårare</strong> för dig själv att inte göra det önskade beteendet. Om du till exempel vill äta hälsosammare kan du göra dig av med alla den ohälsosamma maten i ditt hus. På så sätt måste du medvetet anstränga dig för att äta ohälsosam mat och det är mindre troligt att du gör det.</p>
            <p>När man försöker bygga en ny vana är det också viktigt att försöka börja enkelt och bygga upp vanan eftersom. Börja med något litet och lätthanterligt eftersom det gör det enklare för kroppen och hjärnan att automatisera. Därefter kan du gradvis göra det svårare.</p>
            <p>Det är också viktigt att ha tålamod när du försöker bygga upp en ny vana eftersom det tar tid innan den blir en del av ditt liv. Det sägs att det tar minst <strong>21 dagar att skapa en ny vana</strong>, men detta kan variera från person till person.</p>
            <GatsbyImage image={data.image2.childImageSharp.gatsbyImageData} className="rounded-xl" alt="träningssaker" />
            <h2 id="hur-kan-man-enklare-skapa-nya-vanor-">Hur kan man enklare skapa nya vanor?</h2>
            <p>Ett sätt att skapa nya och bättre vanor är att använda sig av <strong>&quot;habit stacking&quot;</strong> eller vana stapling som det heter på svenska. Det innebär att du kopplar den nya vanan till en befintlig vana som du redan har.</p>
            <p>Om du till exempel vill börja springa varje morgon kan du koppla det till en annan morgonvana, till exempel att dricka kaffe. Så efter att du har druckit kaffe tar du på dig löparskorna och tar en löprunda.</p>
            <p>Ett annat sätt för att skapa nya vanor är att använda det som kallas en <strong>vaneuppföljare</strong>. Detta är ett verktyg som hjälper dig att följa dina framsteg med den vana du försöker bygga upp. Genom att se dina framsteg blir du mer motiverad att fortsätta med vanan eftersom du inte vill bryta trenden.</p>
            <p>Vanligtvis kan du utföra vaneuppföljning på ett papper eller en kalender med ett antal rutor där du  du kan färglägga eller kryssa av varje dag som du gör det önskade beteendet. Men det finns också många appar för att följa upp vanor som kan hjälpa dig med detta.</p>
            <p>Slutligen kan det också vara bra att ställa in påminnelser för den nya vana du försöker bygga upp. Detta kan göras med en fysisk påminnelse, till exempel genom att lägga dina löparskor bredvid i din säng eller genom att ställa in en digital påminnelse i din telefon.</p>
            <h2 id="hur-blir-man-av-med-en-d-lig-vana-">Hur blir man av med en dålig vana?</h2>
            <p>Ibland vill man inte skapa en ny vana, utan man kanske vill bli av med en dålig vana såsom rökning eller överätning.</p>
            <p>Det första steget är att identifiera den <strong>utlösande faktorn (triggern)</strong> för den dåliga vanan. När du vet vad som utlöser vanan kan du vanligtvis försöka undvika den eller ändra på den. Om du till exempel röker när du dricker kaffe kan du försöka dricka te i stället.</p>
            <p>Ett annat sätt är att hitta ett hälsosamt alternativt beteende som kan ersätta den dåliga vanan. Om du till exempel äter när du är uttråkad kan du försöka läsa eller ta en promenad i stället.</p>
            <p>Vissa vanor kan däremot vara svårt att ersätta, men vanligtvis kan man <strong>försöka ändra på den</strong> vilket gör att du gör det önskade beteendet mindre ofta. Om du till exempel biter på naglarna när du är nervös kan du försöka hålla händerna sysselsatta med något annat, till exempel en stressboll.</p>
            <p>Det är också viktigt att ha tålamod när du försöker bli av med en dålig vana eftersom det tar tid att bryta ett beteende som man tidigare har utfört under en lång tid.</p>
            <h2 id="andra-vanliga-fr-gor-om-vanor">Andra vanliga frågor om vanor</h2>
            <p>Nedan går vi igenom några andra vanliga frågor om vanor.</p>
            <h3 id="hur-l-ng-tid-tar-det-att-skapa-en-ny-vana-">Hur lång tid tar det att skapa en ny vana?</h3>
            <p>Som vi nämnde tidigare sägs det att det tar minst 21 dagar att skapa en ny vana, men detta kan variera från person till person. Hur lång tid som det tar beror också på hur komplicerad vanan är att utföra.</p>
            <p>Det finns också en del forskning som har visat att det kan ta upp till <a href="/blogg/tiden-att-bygga-vanor/">66 dagar</a> innan en ny vana blir automatisk. Så om du har svårt att skapa en ny vana, ge inte upp för tidigt.</p>
            <h3 id="kan-du-ndra-en-d-lig-vana-">Kan du ändra en dålig vana?</h3>
            <p>Ja, du alltid ändra på en dålig vana med det kan ta tid. Kom ihåg att ha tålamod och prova olika saker tills du hittar något som fungerar för dig.</p>
            <h3 id="vilka-r-n-gra-vanliga-d-liga-vanor-">Vilka är några vanliga dåliga vanor?</h3>
            <p>Det finns många olika dåliga vanor, men några av de vanligaste är rökning, nagelbitning, uppskjutande, överätning och överdrivet drickande.</p>
            <h3 id="beh-ver-vi-vara-medvetna-om-v-ra-vanor-">Behöver vi vara medvetna om våra vanor?</h3>
            <p>Vanor sker automatiskt och vanligtvis behöver man inte vara så medveten om sina vanor. Däremot  det kan vara bra att vara medveten om sina vanor om man vill ändra dem.</p>
            <p>Om du exempelvis vill sluta med rökning behöver du först vara medveten om vad det är som får dig att vilja röka.</p>
            <h3 id="vilka-r-n-gra-goda-vanor-">Vilka är några goda vanor?</h3>
            <p>Det finns många olika goda vanor, men några av de vanligaste är att motionera, äta hälsosamt, studera regelbundet och få tillräckligt med sömn. Goda vanor kan hjälpa dig att leva ett hälsosammare och lyckligare liv.</p>
            <p>Vi bör alltid försöka maximera och skapa nya goda vanor i våra liv för att hjälper oss att nå våra mål. Men vi bör också vara medvetna om våra dåliga vanor så att vi kan försöka ändra dem.</p>
          </article>
        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "habits.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "trana-saker.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
